import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NavLink, useLocation } from "react-router-dom";
import NestedLinks from "./NestedLinks";
import "../App.css";
import { Info, Logout } from "@mui/icons-material";
import { useState } from "react";
import { logoutUser } from "utils";

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: "red",
  },
  root: {
    width: "90%",
    margin: "0 auto",
    maxWidth: 360,
  },
  nested: {
    paddingLeft: 4,
  },
  linkColor: {
    "&:hover": {
      color: "white",
    },
    color: "white",
  },
  activeClassName: {
    "& > div": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

export default function NestedList({ link }) {
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const location = useLocation();

  let WindowLocation = window.location.pathname.split("/");

  return (
    <>
      <List
        component="nav"
        dense
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        {link.routes.map((route, index) => {
          let LocationName = route.link.split("/");
          return route.children ? (
            <NestedLinks key={index + route.link} route={route} />
          ) : (
            <Tooltip
              title={route.label}
              color="white"
              arrow
              placement="right"
              key={index + 11}
            >
              <NavLink
                key={index + 10}
                exact={route.exact}
                to={route.link}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
                className={(navData) =>
                  !navData.isActive
                    ? classes.linkColor
                    : classes.activeClassName
                }
                onClick={() => {
                  if (route.onClick) {
                    route.onClick();
                  }
                }}
              >
                <ListItem
                  // button
                  sx={{
                    marginBottom: "2px",
                    marginTop: "2px",
                    padding: "5px",
                    borderRadius: "5px",
                    marginLeft: "10px",
                    backgroundColor:
                      WindowLocation[2] === LocationName[2] ? "white" : "",
                  }}
                  key={index + 1}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        WindowLocation[2] === LocationName[2]
                          ? "#3160d4"
                          : "white",
                    }}
                    key={index * 1}
                  >
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color:
                        WindowLocation[2] === LocationName[2]
                          ? "#3160d4"
                          : "white",
                    }}
                    primary={route.label}
                    key={index + 2}
                  />
                </ListItem>
              </NavLink>
            </Tooltip>
          );
        })}
      </List>
      <List
        component="nav"
        dense
        aria-labelledby="nested-list-subheader"
        style={{
          padding: "0rem",
        }}
      >
        <Divider
          style={{
            backgroundColor: "white",
          }}
        />
        <Tooltip title={"Logout"} color="white">
          <NavLink
            key={99}
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <ListItem
              button
              sx={{
                marginBottom: "2px",
                marginTop: "2px",
                padding: "5px",
                borderRadius: "5px",
                marginLeft: "14px",
              }}
            >
              <ListItemIcon sx={{ color: "white" }}>
                <Logout fontSize="small" />
              </ListItemIcon>
              <ListItemText sx={{ color: "white" }} primary={"Logout"} />
            </ListItem>
          </NavLink>
        </Tooltip>
      </List>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid sx={{ display: "flex", alignItems: "center", px: 2, pt: 2 }}>
          <Info fontSize="small" sx={{ color: "red" }} />
          <DialogTitle
            sx={{ padding: 0, ml: 1, fontSize: 18 }}
            id="alert-dialog-title"
          >
            Are you leaving?
          </DialogTitle>
        </Grid>
        <DialogContent sx={{ px: 2, py: 2 }}>
          <DialogContentText id="alert-dialog-description">
            Are you sure, You want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 2, paddingBottom: 2 }}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={logoutUser}>Logout</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
